<template>
  <div class="basic-layout max-w-full">
    <h3 class="mb-4 subtitle max-w-fit">Clients feedback</h3>
    <div class="basic-layout gap-y-44 pb-8">
      <div class="basic-layout" v-for="feedback in feedbacks" :key="feedback">
        <div class="basic-layout pb-4 h-fit gap-y-2 feedback-text-size">
          <img class="max-w-40 max-h-lg rounded-xl" :src="$iconPath(feedback.photo)" />
          <h3 class="text-wrap max-w-sm subtitle">
            {{ feedback.name }}
          </h3>
          <p class="text-wrap max-w-sm">
            {{ feedback.position }}
          </p>
          <a class="ref-in-paragraph max-w-sm" :href="feedback.linkedin">{{ feedback.linkedin }}</a>
          <a
            class="ref-in-paragraph max-w-sm"
            v-if="feedback.email"
            :href="'mailto:' + feedback.email"
            >{{ feedback.email }}</a
          >
        </div>
        <p class="paragraph">
          {{ feedback.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Feedback } from "@/store/about/feedback";

export default defineComponent({
  name: "FeedbacksList",
  props: { feedbacks: Array as PropType<Array<Feedback>> },
});
</script>
