import { defineStore } from "pinia";

interface State {
  searched: string;
  fuzzy: boolean;
  matchCase: boolean;
}

export const useSearchStore = defineStore("search", {
  state: (): State => {
    return { searched: "", fuzzy: false, matchCase: false };
  },
});
