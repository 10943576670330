import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "personal-description",
  class: "basic-layout"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<p class=\"paragraph animate-show-and-slide-from-left-1\"> I am full-stack engineer with vast experience in building user-oriented products, communicating with customers and high-level stackholders, understanding business needs and its impact in future, improving and fixing software solutions which bring value to people and their business. Took participance and led development of startups, enterprise companies projects and contributed into famous opensource projects such as <a class=\"ref-in-paragraph\" href=\"https://github.com/smartdevicelink\" target=\"_blank\">SmartDeviceLink</a>, particularly <a class=\"ref-in-paragraph\" href=\"https://github.com/smartdevicelink/sdl_core\" target=\"_blank\">SDL Core</a> and <a class=\"ref-in-paragraph\" href=\"https://github.com/smartdevicelink/sdl_hmi\" target=\"_blank\">SDL HMI</a>. Apart from creating products from scratch I am also good and quick in understanding of already existing software which requires fixes or improvements. </p><p class=\"paragraph self-start animate-show-and-slide-from-left-1\"> Contact me so we can solve your problems or implement new ideas together! </p>", 2)
  ])))
}