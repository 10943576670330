import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "personal-info-item-name" }
const _hoisted_2 = {
  key: 0,
  class: "personal-info-item-name"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "personal-info-item-name"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  key: 2,
  class: "personal-info-item-name"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 3,
  class: "personal-info-item-value"
}
const _hoisted_9 = { class: "personal-info-row" }
const _hoisted_10 = { class: "flex flex-row gap-x-2" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 0,
  class: "bg-transparent invert hover:bg-blue-500 hover:invert-0 hover:scale-125 rounded-full duration-500",
  width: "32",
  height: "32",
  src: "https://design.codeberg.org/logo-kit/icon_inverted.svg"
}
const _hoisted_13 = {
  key: 1,
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24",
  class: "hover:scale-125 hover:invert hover:bg-white duration-500 rounded-full"
}
const _hoisted_14 = {
  key: 2,
  width: "32",
  height: "32",
  viewBox: "0 0 16 16",
  xmlns: "http://www.w3.org/2000/svg",
  class: "hover:scale-125 hover:fill-blue-600 hover:bg-white duration-500 rounded-lg"
}
const _hoisted_15 = {
  key: 3,
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  version: "1.1",
  width: "32",
  height: "32",
  viewBox: "0 0 24 24",
  "xml:space": "preserve",
  class: "hover:scale-125 duration-500 rounded-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, (item, key) => {
        return (_openBlock(), _createElementBlock("tr", {
          class: "personal-info-row",
          key: key
        }, [
          _createElementVNode("td", _hoisted_1, _toDisplayString(_ctx.$toCapitalCase(key)) + ":", 1),
          (key === 'email')
            ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                _createElementVNode("a", {
                  class: "project-link",
                  href: 'mailto:' + item
                }, _toDisplayString(item), 9, _hoisted_3)
              ]))
            : (key === 'phone')
              ? (_openBlock(), _createElementBlock("td", _hoisted_4, [
                  _createElementVNode("a", {
                    class: "project-link",
                    href: 'tel:' + item
                  }, _toDisplayString(item), 9, _hoisted_5)
                ]))
              : (key === 'skype')
                ? (_openBlock(), _createElementBlock("td", _hoisted_6, [
                    _createElementVNode("a", {
                      class: "project-link",
                      href: 'skype:' + item + '?call'
                    }, _toDisplayString(item), 9, _hoisted_7)
                  ]))
                : (_openBlock(), _createElementBlock("td", _hoisted_8, _toDisplayString(item), 1))
        ]))
      }), 128)),
      _createElementVNode("tr", _hoisted_9, [
        _cache[3] || (_cache[3] = _createElementVNode("td", { class: "flex flex-col gap-x-2 justify-start" }, "Profiles:", -1)),
        _createElementVNode("td", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item, key) => {
            return (_openBlock(), _createElementBlock("a", {
              class: "project-link",
              key: key,
              href: item
            }, [
              (key === 'codeberg')
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : _createCommentVNode("", true),
              (key === 'github')
                ? (_openBlock(), _createElementBlock("svg", _hoisted_13, _cache[0] || (_cache[0] = [
                    _createElementVNode("path", { d: "M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (key === 'linkedin')
                ? (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[1] || (_cache[1] = [
                    _createElementVNode("path", { d: "M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z" }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (key === 'medium')
                ? (_openBlock(), _createElementBlock("svg", _hoisted_15, _cache[2] || (_cache[2] = [
                    _createElementVNode("defs", null, null, -1),
                    _createElementVNode("g", {
                      style: {"stroke":"none","stroke-width":"0","stroke-dasharray":"none","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"10","fill":"none","fill-rule":"nonzero","opacity":"1"},
                      transform: "translate(1.4065934065934016 1.4065934065934016) scale(0.25, 0.25)"
                    }, [
                      _createElementVNode("path", {
                        d: "M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 70 25.993 l -3.992 3.827 c -0.344 0.262 -0.515 0.693 -0.444 1.12 v 28.12 c -0.071 0.427 0.099 0.858 0.444 1.12 l 3.898 3.827 v 0.84 H 50.299 v -0.84 l 4.038 -3.92 c 0.397 -0.397 0.397 -0.513 0.397 -1.12 V 36.237 L 43.506 64.754 h -1.517 L 28.917 36.237 V 55.35 c -0.109 0.804 0.158 1.612 0.724 2.194 l 5.252 6.371 v 0.84 H 20 v -0.84 l 5.252 -6.371 c 0.562 -0.582 0.813 -1.396 0.677 -2.194 V 33.25 c 0.062 -0.614 -0.172 -1.22 -0.63 -1.633 l -4.669 -5.624 v -0.84 h 14.496 l 11.204 24.573 l 9.851 -24.573 H 70 V 25.993 z",
                        style: {"stroke":"none","stroke-width":"1","stroke-dasharray":"none","stroke-linecap":"butt","stroke-linejoin":"miter","stroke-miterlimit":"10","fill":"rgb(29, 29, 27)","fill-rule":"nonzero","opacity":"1"},
                        transform: " matrix(1 0 0 1 0 0) ",
                        "stroke-linecap": "round"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ], 8, _hoisted_11))
          }), 128))
        ])
      ])
    ])
  ]))
}