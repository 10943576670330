<template>
  <div class="tech-stack-card">
    <p class="pt-2 subtitle">{{ stack.name }}</p>
    <div
      :class="
        stack.technologies.length < 6 ? 'tech-stack-card-element' : 'tech-stack-card-element-xl'
      "
    >
      <div v-for="t in stack.technologies" :key="t">
        <img class="min-w-[32px]" :src="$iconPath(t.icon + '.svg')" width="32px" height="32px" />
        <p>{{ t.name }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TechnologyStack } from "@/store/technologies/technology_stack";

export default defineComponent({
  name: "TechStackCard",
  props: { stack: Object as PropType<TechnologyStack> },
});
</script>
