import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "basic-layout" }
const _hoisted_2 = { class: "basic-layout py-4 gap-y-16" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "project-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _createElementVNode("a", {
            class: "project-link -mb-8",
            href: project.link
          }, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(project.name), 1)
          ], 8, _hoisted_3),
          _createElementVNode("img", {
            class: "inline -mb-8 rounded-xl w-[90vw]",
            src: project.gif
          }, null, 8, _hoisted_5),
          _createElementVNode("p", _hoisted_6, _toDisplayString(project.description), 1)
        ], 64))
      }), 128))
    ])
  ]))
}