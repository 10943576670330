import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "basic-layout gap-y-8" }
const _hoisted_2 = { class: "basic-layout gap-x-4 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_company_card = _resolveComponent("company-card")!
  const _component_horizontal_list = _resolveComponent("horizontal-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "subtitle" }, "Companies I worked with", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_horizontal_list, { styleClass: "company-cards-container" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company) => {
            return (_openBlock(), _createBlock(_component_company_card, {
              key: company,
              info: company
            }, null, 8, ["info"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}