<template>
  <nav>
    <router-link class="router-link" to="/projects">Projects</router-link>
    <router-link class="router-link" to="/">About me</router-link>
    <router-link class="router-link" to="/tech">Technologies</router-link>
  </nav>
  <router-view class="pt-10" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({ name: "App" });
</script>
