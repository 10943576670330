import { defineStore } from "pinia";
import projects from "../../assets/projects.json";
import { PersonalProject } from "./personal";

interface State {
  projects: PersonalProject[];
}

export const useProjectsStore = defineStore("projects", {
  state: (): State => {
    return { projects };
  },
});
