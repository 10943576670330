import { defineStore } from "pinia";
import technologies from "../../assets/technologies.json";
import stacks from "../../assets/stacks.json";
import { TechnologyStack } from "./technology_stack";
import { useSearchStore } from "@/store/search/index";

export interface PreparedTechnology {
  name: string;
  experience: string;
  lastUsed: number;
  additional?: string;
}

interface OriginalTech {
  name: string;
  started: number;
  lastUsed: number | string;
  additional: string;
}

interface Technologies {
  programmingLanguages: OriginalTech[];
  databases: OriginalTech[];
  operatingSystems: OriginalTech[];
  "C++Stack": OriginalTech[];
  pythonStack: OriginalTech[];
  versionControlSystems: OriginalTech[];
  devops: OriginalTech[];
  frontend: OriginalTech[];
}

export type PreparedTechnologies = Record<keyof Technologies, PreparedTechnology[]>;

function prepare(t: OriginalTech): PreparedTechnology {
  const { name, started, lastUsed, additional } = t;

  const startedDate: Date = new Date();
  startedDate.setFullYear(started);

  const lastUsedDate: Date = new Date();
  if (lastUsed !== "Now") {
    lastUsedDate.setFullYear(Number(lastUsed));
  }

  // in months
  const experience: Date = new Date();
  experience.setFullYear(lastUsedDate.getFullYear() - started);
  experience.setMonth(lastUsedDate.getMonth());

  const result: PreparedTechnology = {
    name,
    experience:
      experience.getFullYear() === 0
        ? `${experience.getMonth() + 1} months`
        : `${experience.getFullYear()} years ${experience.getMonth() + 1} months`,
    lastUsed: lastUsedDate.getFullYear(),
  };
  if (additional.length) {
    result.additional = additional;
  }
  return result;
}

function findTech(
  technologies: PreparedTechnologies,
  searched: string,
  fuzzy: boolean,
  matchCase: boolean
): PreparedTechnology[] {
  if (!searched) {
    return [];
  }

  let filteredData: PreparedTechnology[] = [];
  for (const [_, data] of Object.entries(technologies)) {
    filteredData = filteredData.concat(
      data.filter((t: PreparedTechnology): boolean => {
        const name = matchCase ? t.name : t.name.toLowerCase();
        return fuzzy
          ? name.includes(matchCase ? searched : searched.toLowerCase())
          : name.startsWith(matchCase ? searched : searched.toLowerCase());
      })
    );
  }
  return filteredData;
}

function findTechGroups(
  technologies: PreparedTechnologies,
  searched: string,
  fuzzy: boolean,
  matchCase: boolean
): Partial<PreparedTechnologies> {
  const filteredData: Partial<PreparedTechnologies> = {};

  if (!searched.length) return filteredData;

  for (const [key, data] of Object.entries(technologies)) {
    const filteredSectionData = data.filter((t: PreparedTechnology): boolean => {
      const name = matchCase ? t.name : t.name.toLowerCase();
      return fuzzy
        ? name.includes(matchCase ? searched : searched.toLowerCase())
        : name.startsWith(matchCase ? searched : searched.toLowerCase());
    });

    if (filteredSectionData.length > 0) {
      filteredData[key as keyof PreparedTechnologies] = filteredSectionData;
    }
  }

  return filteredData;
}

interface State {
  headers: string[];
  technologies: PreparedTechnologies;
  stacks: TechnologyStack[];
}

export const useTechnologiesStore = defineStore("technologies", {
  state: (): State => {
    const techs: Technologies = technologies.technologies;
    const preparedTech: PreparedTechnologies = {
      programmingLanguages: [],
      databases: [],
      operatingSystems: [],
      "C++Stack": [],
      pythonStack: [],
      versionControlSystems: [],
      devops: [],
      frontend: [],
    };

    for (const [key, value] of Object.entries(techs)) {
      preparedTech[key as keyof PreparedTechnologies] = value.map(prepare);
    }

    const headers: string[] = technologies.headers;
    return { technologies: preparedTech, stacks, headers };
  },
  getters: {
    techFound: (state: State): PreparedTechnology[] => {
      const { searched, fuzzy, matchCase } = useSearchStore();
      return findTech(state.technologies, searched, fuzzy, matchCase);
    },
    techGroupsFound: (state: State): Partial<PreparedTechnologies> => {
      const { searched, fuzzy, matchCase } = useSearchStore();
      return findTechGroups(state.technologies, searched, fuzzy, matchCase);
    },
  },
});
