import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "basic-layout max-w-full" }
const _hoisted_2 = { class: "basic-layout gap-y-44 pb-8" }
const _hoisted_3 = { class: "basic-layout pb-4 h-fit gap-y-2 feedback-text-size" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-wrap max-w-sm subtitle" }
const _hoisted_6 = { class: "text-wrap max-w-sm" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "paragraph" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "mb-4 subtitle max-w-fit" }, "Clients feedback", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.feedbacks, (feedback) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "basic-layout",
          key: feedback
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "max-w-40 max-h-lg rounded-xl",
              src: _ctx.$iconPath(feedback.photo)
            }, null, 8, _hoisted_4),
            _createElementVNode("h3", _hoisted_5, _toDisplayString(feedback.name), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(feedback.position), 1),
            _createElementVNode("a", {
              class: "ref-in-paragraph max-w-sm",
              href: feedback.linkedin
            }, _toDisplayString(feedback.linkedin), 9, _hoisted_7),
            (feedback.email)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "ref-in-paragraph max-w-sm",
                  href: 'mailto:' + feedback.email
                }, _toDisplayString(feedback.email), 9, _hoisted_8))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("p", _hoisted_9, _toDisplayString(feedback.text), 1)
        ]))
      }), 128))
    ])
  ]))
}