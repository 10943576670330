<template>
  <div class="basic-layout gap-y-2">
    <input class="search-bar" type="text" placeholder="Type here to search" v-model="searched" />
    <div class="flex flex-row justify-between gap-x-4">
      <div>
        <input
          class="scale-125 accent-zinc-800"
          type="checkbox"
          id="fuzzy_search"
          v-model="fuzzy"
        />
        <label class="px-2" for="fuzzy_search">fuzzy</label>
      </div>
      <div>
        <input
          class="scale-125 accent-zinc-800"
          type="checkbox"
          id="match_case"
          v-model="matchCase"
        />
        <label class="px-2" for="match_case">match case</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useSearchStore } from "@/store/search/index";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "SearchBar",
  data() {
    return storeToRefs(useSearchStore());
  },
});
</script>
