import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "basic-layout gap-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tech_stack_card = _resolveComponent("tech-stack-card")!
  const _component_horizontal_list = _resolveComponent("horizontal-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "subtitle" }, "Tools I use", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-xs sm:text-sm" }, [
      _createTextVNode(" Visit "),
      _createElementVNode("a", {
        class: "ref-in-paragraph",
        href: "/#/tech"
      }, "technologies"),
      _createTextVNode(" for detailed info ")
    ], -1)),
    _createVNode(_component_horizontal_list, {
      styleClass: "tech-stack-cards-container",
      customClass: "tech-stack-card"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stacks, (stack) => {
          return (_openBlock(), _createBlock(_component_tech_stack_card, {
            key: stack,
            stack: stack
          }, null, 8, ["stack"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}