<template>
  <div class="talent-card">
    <img :src="img" loading="lazy" />
    <p>{{ talent }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TalentCard",
  props: { img: String, talent: String },
});
</script>
