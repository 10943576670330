import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col p-4" }
const _hoisted_2 = { id: "technologies-table" }
const _hoisted_3 = { class: "border-2 border-zinc-500" }
const _hoisted_4 = {
  class: "text-center py-4 border-y border-zinc-500",
  colspan: "4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        _createElementVNode("tr", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "border border-zinc-500",
              key: header
            }, _toDisplayString(header), 1))
          }), 128))
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techs, ([section, data]) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: section }, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$toCapitalCase(section)), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data, (t) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "skill-row",
                key: t
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(t), (v) => {
                  return (_openBlock(), _createElementBlock("td", {
                    class: "border-x border-zinc-500",
                    key: v
                  }, _toDisplayString(v), 1))
                }), 128))
              ]))
            }), 128))
          ], 64))
        }), 128))
      ])
    ])
  ]))
}