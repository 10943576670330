<template>
  <div class="basic-layout gap-y-8">
    <h3 class="subtitle">Companies I worked with</h3>
    <div class="basic-layout gap-x-4 px-4">
      <horizontal-list styleClass="company-cards-container">
        <company-card v-for="company in companies" :key="company" :info="company" />
      </horizontal-list>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CompanyCard from "@/components/CompanyCard.vue";
import HorizontalList from "@/components/HorizontalList.vue";
import { Company } from "@/store/about/company";

export default defineComponent({
  name: "CompaniesList",
  components: { CompanyCard, HorizontalList },
  props: { companies: Array as PropType<Array<Company>> },
});
</script>
