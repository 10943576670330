import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tech-stack-card" }
const _hoisted_2 = { class: "pt-2 subtitle" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.stack.name), 1),
    _createElementVNode("div", {
      class: _normalizeClass(
        _ctx.stack.technologies.length < 6 ? 'tech-stack-card-element' : 'tech-stack-card-element-xl'
      )
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stack.technologies, (t) => {
        return (_openBlock(), _createElementBlock("div", { key: t }, [
          _createElementVNode("img", {
            class: "min-w-[32px]",
            src: _ctx.$iconPath(t.icon + '.svg'),
            width: "32px",
            height: "32px"
          }, null, 8, _hoisted_3),
          _createElementVNode("p", null, _toDisplayString(t.name), 1)
        ]))
      }), 128))
    ], 2)
  ]))
}