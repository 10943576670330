<template>
  <div class="basic-layout gap-y-8">
    <h3 class="subtitle">Tools I use</h3>
    <p class="text-xs sm:text-sm">
      Visit <a class="ref-in-paragraph" href="/#/tech">technologies</a> for detailed info
    </p>
    <horizontal-list styleClass="tech-stack-cards-container" customClass="tech-stack-card">
      <tech-stack-card v-for="stack in stacks" :key="stack" :stack="stack" />
    </horizontal-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useTechnologiesStore } from "@/store/technologies/index";
import { mapState } from "pinia";
import TechStackCard from "./TechStackCard.vue";
import HorizontalList from "./HorizontalList.vue";

export default defineComponent({
  name: "TechnologiesStack",
  components: { TechStackCard, HorizontalList },
  computed: {
    ...mapState(useTechnologiesStore, ["stacks"]),
  },
});
</script>
