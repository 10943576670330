<template>
  <div>
    <search-bar ref="searchBar" />
    <technologies-table
      :headers="headers"
      :technologies="technologies"
      :searched="searched"
      :fuzzy="fuzzy"
      :matchCase="matchCase"
      class="animate-tableappearance"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TechnologiesTable from "@/components/TechnologiesTable.vue";
import SearchBar from "@/components/SearchBar.vue";
import { useTechnologiesStore } from "@/store/technologies/index";
import { mapState } from "pinia";
import { useSearchStore } from "@/store/search/index";

export default defineComponent({
  name: "TechnologiesView",
  components: {
    TechnologiesTable,
    SearchBar,
  },
  mounted: function (this: any) {
    this.$refs.searchBar.$el.scrollIntoView({
      behavior: "smooth",
      inline: "start",
      block: "nearest",
    });
  },
  computed: {
    ...mapState(useTechnologiesStore, ["headers", "technologies"]),
    ...mapState(useSearchStore, ["searched", "fuzzy", "matchCase"]),
  },
});
</script>
