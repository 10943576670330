<template>
  <div class="basic-layout gap-y-4">
    <h3 class="subtitle">What I can do for you</h3>
    <horizontal-list :styleClass="'talent-cards-container'">
      <talent-card
        v-for="talent of talents"
        :key="talent"
        :img="$iconPath(talent.icon)"
        :talent="talent.info"
      />
    </horizontal-list>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TalentCard from "@/components/TalentCard.vue";
import HorizontalList from "@/components/HorizontalList.vue";

export default defineComponent({
  name: "TalentsList",
  components: { TalentCard, HorizontalList },
  computed: {
    talents: function (this: any): Record<string, string>[] {
      return [
        {
          icon: "3clouds.webp",
          info: "Cloud Applications and adjacent infrastructure",
        },
        {
          icon: "microservices.webp",
          info: "Monolith and microservices architecture",
        },
        {
          icon: "webapp.webp",
          info: "Web Applications",
        },
        {
          icon: "desktopapp.webp",
          info: "Desktop Applications",
        },
        {
          icon: "API.webp",
          info: "APIs and libraries",
        },
        {
          icon: "cicd.webp",
          info: "Setup CI/CD pipelines",
        },
      ];
    },
  },
});
</script>
