<template>
  <div id="personal-description" class="basic-layout">
    <p class="paragraph animate-show-and-slide-from-left-1">
      I am full-stack engineer with vast experience in building user-oriented products,
      communicating with customers and high-level stackholders, understanding business needs and its
      impact in future, improving and fixing software solutions which bring value to people and
      their business. Took participance and led development of startups, enterprise companies
      projects and contributed into famous opensource projects such as
      <a class="ref-in-paragraph" href="https://github.com/smartdevicelink" target="_blank"
        >SmartDeviceLink</a
      >, particularly
      <a class="ref-in-paragraph" href="https://github.com/smartdevicelink/sdl_core" target="_blank"
        >SDL Core</a
      >
      and
      <a class="ref-in-paragraph" href="https://github.com/smartdevicelink/sdl_hmi" target="_blank"
        >SDL HMI</a
      >. Apart from creating products from scratch I am also good and quick in understanding of
      already existing software which requires fixes or improvements.
    </p>
    <p class="paragraph self-start animate-show-and-slide-from-left-1">
      Contact me so we can solve your problems or implement new ideas together!
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DescriptionInfo",
});
</script>
