import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_technologies_table = _resolveComponent("technologies-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_bar, { ref: "searchBar" }, null, 512),
    _createVNode(_component_technologies_table, {
      headers: _ctx.headers,
      technologies: _ctx.technologies,
      searched: _ctx.searched,
      fuzzy: _ctx.fuzzy,
      matchCase: _ctx.matchCase,
      class: "animate-tableappearance"
    }, null, 8, ["headers", "technologies", "searched", "fuzzy", "matchCase"])
  ]))
}