<template>
  <div class="basic-layout">
    <div class="basic-layout py-4 gap-y-16">
      <template v-for="(project, index) in projects" :key="index">
        <a class="project-link -mb-8" :href="project.link">
          <h2 class="subtitle">{{ project.name }}</h2>
        </a>
        <img class="inline -mb-8 rounded-xl w-[90vw]" :src="project.gif" />
        <p class="project-description">{{ project.description }}</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { PersonalProject } from "@/store/projects/personal";

export default defineComponent({
  name: "PersonalProjects",
  props: { projects: { type: Array as PropType<PersonalProject[]>, required: true } },
});
</script>
