import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "horizontal-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_button = _resolveComponent("list-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_list_button, {
      onClick: _ctx.scrollLeft,
      disabled: _ctx.scrollLeftDisabled,
      style: ""
    }, null, 8, ["onClick", "disabled"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.styleClass),
      ref: "listContainer",
      onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
      onTouchend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 34),
    _createVNode(_component_list_button, {
      onClick: _ctx.scrollRight,
      disabled: _ctx.scrollRightDisabled,
      direction: 'right'
    }, null, 8, ["onClick", "disabled"])
  ]))
}