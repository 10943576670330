<template>
  <div>
    <personal-projects :projects="projects" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PersonalProjects from "@/components/PersonalProjects.vue";
import { useProjectsStore } from "@/store/projects/projects";
import { mapState } from "pinia";

export default defineComponent({
  name: "ProjectsView",
  components: {
    PersonalProjects,
  },
  computed: {
    ...mapState(useProjectsStore, ["projects"]),
  },
});
</script>
