<template>
  <div class="basic-layout gap-y-4 animate-appearance-1s">
    <img
      class="max-w-60 inline rounded-full w-44 sm:w-60"
      alt="Personal photo"
      src="@/assets/photo.webp"
    />
    <h1 class="title">{{ name }}</h1>
    <personal-info :info="personal" :links="links" />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Personal } from "@/store/about/personal";
import { Links } from "@/store/about/links";
import PersonalInfo from "@/components/PersonalInfo.vue";

export default defineComponent({
  name: "ContactsTitle",
  components: { PersonalInfo },
  props: {
    name: String,
    personal: Object as PropType<Personal>,
    links: Object as PropType<Links>,
  },
});
</script>
