import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/photo.webp'


const _hoisted_1 = { class: "basic-layout gap-y-4 animate-appearance-1s" }
const _hoisted_2 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_personal_info = _resolveComponent("personal-info")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "max-w-60 inline rounded-full w-44 sm:w-60",
      alt: "Personal photo",
      src: _imports_0
    }, null, -1)),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createVNode(_component_personal_info, {
      info: _ctx.personal,
      links: _ctx.links
    }, null, 8, ["info", "links"])
  ]))
}