<template>
  <div class="horizontal-list">
    <list-button @click="scrollLeft" :disabled="scrollLeftDisabled" style />
    <div
      :class="styleClass"
      ref="listContainer"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
    >
      <slot />
    </div>
    <list-button @click="scrollRight" :disabled="scrollRightDisabled" :direction="'right'" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SafeIndex } from "@/utils/safe_index";
import ListButton from "@/components/ListButton.vue";
export default defineComponent({
  name: "HorizontalList",
  components: { ListButton },
  props: {
    styleClass: { type: String, required: true },
    customClass: { type: String, default: undefined },
    dynamicNumberOfElements: { type: Number, default: undefined },
  },
  data() {
    return {
      scrollStart: 0,
      scrollEnd: 0,
      currentItem: new SafeIndex(0, 1),
      slotElements: HTMLCollection,
    };
  },
  watch: {
    dynamicNumberOfElements: function (this: any, newValue: number, _: number) {
      this.currentItem = new SafeIndex(0, newValue - 1);
      this.slotElements = this.customClass
        ? this.$refs.listContainer.getElementsByClassName(this.customClass)
        : this.$refs.listContainer.getElementsByTagName("div");
    },
  },
  mounted(this: any) {
    this.slotUpdated();
  },
  methods: {
    slotUpdated: function (this: any) {
      this.slotElements = this.customClass
        ? this.$refs.listContainer.getElementsByClassName(this.customClass)
        : this.$refs.listContainer.getElementsByTagName("div");
      this.currentItem = new SafeIndex(0, this.slotElements.length - 1);
    },
    scrollLeft: function (this: any) {
      this.slotElements.item(Number(this.currentItem.decrease())).scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    },
    scrollRight: function (this: any) {
      this.slotElements.item(Number(this.currentItem.increase())).scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    },
    handleTouchStart: function (this: any, event: TouchEvent) {
      this.scrollStart = event.changedTouches[0].pageX;
    },
    handleTouchEnd: function (this: any, event: TouchEvent) {
      this.scrollEnd = event.changedTouches[0].pageX;
      const deltaY = this.scrollStart - this.scrollEnd;
      if (deltaY > 10) return this.scrollRight();
      if (deltaY < -10) return this.scrollLeft();
    },
  },
  computed: {
    scrollLeftDisabled: function (this: any): boolean {
      return this.currentItem.atMin();
    },
    scrollRightDisabled: function (this: any): boolean {
      return this.currentItem.atMax();
    },
  },
});
</script>
