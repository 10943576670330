import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "basic-layout gap-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_talent_card = _resolveComponent("talent-card")!
  const _component_horizontal_list = _resolveComponent("horizontal-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "subtitle" }, "What I can do for you", -1)),
    _createVNode(_component_horizontal_list, { styleClass: 'talent-cards-container' }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.talents, (talent) => {
          return (_openBlock(), _createBlock(_component_talent_card, {
            key: talent,
            img: _ctx.$iconPath(talent.icon),
            talent: talent.info
          }, null, 8, ["img", "talent"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}