export class SafeIndex {
  constructor(private min: number, private max: number, private value: number = 0) {
    if (min > max) {
      throw Error("Min cannot be more than Max");
    }
  }
  increase(): this {
    if (this.max !== this.value) this.value += 1;
    return this;
  }
  decrease(): this {
    if (this.min !== this.value) this.value -= 1;
    return this;
  }
  valueOf(): number {
    return this.value;
  }
  toFixed(fractionDigits?: number): string {
    return this.value.toFixed(fractionDigits);
  }
  atMin(): boolean {
    return this.min === this.value;
  }
  atMax(): boolean {
    return this.max === this.value;
  }
}
