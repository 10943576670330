import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "basic-layout gap-y-2" }
const _hoisted_2 = { class: "flex flex-row justify-between gap-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "search-bar",
      type: "text",
      placeholder: "Type here to search",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searched) = $event))
    }, null, 512), [
      [_vModelText, _ctx.searched]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "scale-125 accent-zinc-800",
          type: "checkbox",
          id: "fuzzy_search",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fuzzy) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.fuzzy]
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("label", {
          class: "px-2",
          for: "fuzzy_search"
        }, "fuzzy", -1))
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "scale-125 accent-zinc-800",
          type: "checkbox",
          id: "match_case",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.matchCase) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.matchCase]
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("label", {
          class: "px-2",
          for: "match_case"
        }, "match case", -1))
      ])
    ])
  ]))
}