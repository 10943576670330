<template>
  <div class="flex flex-col p-4">
    <table id="technologies-table">
      <tbody>
        <tr class="border-2 border-zinc-500">
          <th class="border border-zinc-500" v-for="header in headers" :key="header">
            {{ header }}
          </th>
        </tr>

        <template v-for="[section, data] of techs" :key="section">
          <tr>
            <th class="text-center py-4 border-y border-zinc-500" colspan="4">
              {{ $toCapitalCase(section) }}
            </th>
          </tr>
          <tr class="skill-row" v-for="t in data" :key="t">
            <td class="border-x border-zinc-500" v-for="v in Object.values(t)" :key="v">
              {{ v }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  useTechnologiesStore,
  PreparedTechnologies,
  PreparedTechnology,
} from "@/store/technologies/index";

export default defineComponent({
  name: "TechnologiesTable",
  props: {
    headers: { type: Array as PropType<string[]>, required: true },
    technologies: { type: Object as PropType<PreparedTechnologies>, required: true },
  },
  computed: {
    searchedTechs: (): Partial<PreparedTechnologies> => {
      const techStore = useTechnologiesStore();
      return techStore.techGroupsFound;
    },
    techs: function (): [string, PreparedTechnology[]][] {
      return Object.keys(this.searchedTechs).length > 0
        ? Object.entries(this.searchedTechs)
        : Object.entries(this.technologies);
    },
  },
});
</script>
