<template>
  <div class="basic-layout">
    <a class="basic-layout company-preview" :href="info.url" target="_blank">
      <img class="rounded-lg" :src="$iconPath(info.imgUrl)" loading="lazy" />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Company } from "@/store/about/company";

export default defineComponent({
  name: "CompanyCard",
  props: { info: Object as PropType<Company> },
});
</script>
