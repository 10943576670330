import { createApp } from "vue";
import { createPinia } from "pinia";
import { iconPath, toCapitalCase } from "@/utils/helpers";
import App from "./App.vue";
import router from "./router";
import "./index.css";

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.config.globalProperties.$iconPath = iconPath;
app.config.globalProperties.$toCapitalCase = toCapitalCase;
app.mount("#app");
